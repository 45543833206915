import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

type TimeToSet = {
  hour?: number;
  minute?: number;
  second?: number;
  millisecond?: number;
};

const monthNorwegian = [
  'Januar',
  'Februar',
  'Mars',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Desember',
];

const weekdayNorwegian = ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'];

export const getClearDate = () => {
  return dayjs().hour(0).minute(0).second(0).millisecond(0);
};

export const addDaysToDate = (days: number) => {
  return dayjs().add(days, 'day').toISOString();
};

export const setTime = (
  date: string,
  { hour = 0, minute = 0, second = 0, millisecond = 0 }: TimeToSet,
): string =>
  dayjs(date).hour(hour).minute(minute).second(second).millisecond(millisecond).toISOString();

export const dateIsBefore = (date: string, dateToBeBefore: string) => {
  return dayjs(date).isBefore(dayjs(dateToBeBefore));
};

export const dateIsBeforeToday = (date: string) => {
  return dayjs(date).isBefore(dayjs());
};

export const dateIsAfter = (date: string, dateToBeAfter: string) => {
  return dayjs(date).isAfter(dayjs(dateToBeAfter));
};

export const dateIsMoreThenADayFromNow = (date: string) => {
  return dayjs(date).isAfter(dayjs().add(1, 'day'));
};

export const dateIsSame = (date: string, dateToBeSame: string) => {
  return dayjs(date).isSame(dayjs(dateToBeSame));
};

export const dateIsSameDate = (date: string, dateToBeSame: string) => {
  if (!dateToBeSame) return false;

  return dayjs(date).isSame(dayjs(dateToBeSame), 'date');
};

export const dateIsAfterOrSame = (date: string, dateToBeAfterOrSame: string) => {
  return dateIsAfter(date, dateToBeAfterOrSame) || dateIsSame(date, dateToBeAfterOrSame);
};

export const stripTimezoneFromDate = (date?: string): string => {
  if (!date) return date;
  const hoursOffset = dayjs(date).utcOffset() / 60;
  return dayjs(date).subtract(hoursOffset, 'hour').toISOString();
};

export const formatDate = (date: any, format: string) => {
  return dayjs(date).format(format);
};

export const getNorwegianFullDate = (date?: string) => {
  if (!date) return '';
  const d = dayjs(date);
  const timeString = d.format('HH:mm');
  const now = dayjs();
  const tomorrow = now.add(1, 'day');
  const yesterDay = now.subtract(1, 'day');

  if (yesterDay.isSame(d, 'day')) {
    return `I går - ${timeString}`;
  }
  if (now.isSame(d, 'day')) {
    return `I dag - ${timeString}`;
  }
  if (tomorrow.isSame(d, 'day')) {
    return `I morgen - ${timeString}`;
  }
  return `${weekdayNorwegian[d.day()]} ${d.date()}. ${monthNorwegian[
    d.month()
  ].toLowerCase()} - ${timeString}`;
};

export const getAgeFromBithday = (date?: string): number => {
  if (!date) return 0;

  return dayjs().diff(dayjs(date), 'year');
};

export const getResendTimeDiff = (date: string, seconds: number): number => {
  return dayjs(date).add(seconds, 'seconds').diff(dayjs(), 'seconds');
};
