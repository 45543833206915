import { KeyboardEvent } from 'react';
import { KeyboardKeys } from '../../types';

export const focusHandler = (
  e: KeyboardEvent<HTMLButtonElement>,
  nextId: string,
  previousId: string,
) => {
  const focusElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      e.preventDefault();
      element.focus();
    }
  };

  switch (e.key) {
    case KeyboardKeys.Up:
    case KeyboardKeys.Left:
      return focusElement(previousId);
    case KeyboardKeys.Right:
    case KeyboardKeys.Down:
      return focusElement(nextId);
    default:
      console.log(`Unhandled key: ${e.key}`);
      return focusElement(nextId);
  }
};
