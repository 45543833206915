import React, { useMemo, ButtonHTMLAttributes } from 'react';
import { useTranslation } from 'next-i18next';
import { CardButton, GhostText, Heading, Text } from '@petrus/ui-library';

import { formatDate } from '../../../common/utils/date.utils';

type Props = {
  date: string;
  onClick: () => void;
  selected?: boolean;
  disabled?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

type DateInfo = {
  simple: string;
  dayOfWeek: string;
  date: string;
  month: string;
};

const DateCard = ({ date, ...props }: Props) => {
  const { t } = useTranslation('common');

  const info: DateInfo = useMemo(
    () => ({
      simple: formatDate(date, 'D MMMM YYYY'),
      dayOfWeek: formatDate(date, 'dd'),
      date: formatDate(date, 'DD'),
      month: formatDate(date, 'MMM'),
    }),
    [date],
  );

  return (
    <CardButton {...props}>
      <GhostText>{info.simple}</GhostText>
      <div className="flex flex-col items-center w-12" aria-hidden="true">
        <Text size="tiny">{t(`domain.availability.daysShort.${info.dayOfWeek}`)}</Text>
        <Heading as="h3" className="py-2">
          {info.date}
        </Heading>
        <Text size="tiny">{t(`domain.availability.month.${info.month}`)}</Text>
      </div>
    </CardButton>
  );
};

export { DateCard };
export type { Props };
