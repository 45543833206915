import { NextApiRequest, NextApiResponse } from 'next';

export type Maybe<T> = T | undefined | null;

export type Species = 'cat' | 'dog';

export type Genders = 'male' | 'female';

export type Generic = { [varname: string]: any };

export enum KeyboardKeys {
  Right = 'ArrowRight',
  Down = 'ArrowDown',
  Left = 'ArrowLeft',
  Up = 'ArrowUp',
  Enter = 'Enter',
}

// Api Types
export type ApiResponse<T> = {
  code: number;
  data: T | null;
  message: string | undefined;
};

export type ApiHandler<T> = (req: NextApiRequest, res: NextApiResponse) => T;

// Web types
export type GetServerSideResult<T> = {
  props: T;
};
