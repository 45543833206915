import React from 'react';
import { SEO, Props as SeoProps } from 'common/components/SEO';

type Props = SeoProps & React.HTMLAttributes<HTMLElement>;

const PageBackbone: React.FunctionComponent<Props> = ({ children, ...props }) => (
  <>
    <SEO {...props} />
    {children}
  </>
);

export { PageBackbone };
export type { Props };
