import React, { useMemo, ButtonHTMLAttributes } from 'react';
import { CardButton, Text } from '@petrus/ui-library';

import { stripTimezoneFromDate, formatDate } from '../../../common/utils/date.utils';

type Props = {
  date: string;
  onClick: () => void;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const TimeCard = ({ date, ...props }: Props) => {
  const time: string = useMemo(() => formatDate(stripTimezoneFromDate(date), 'HH:mm'), [date]);

  return (
    <CardButton className="rounded-md w-full items-center" {...props}>
      <Text>{time}</Text>
    </CardButton>
  );
};

export { TimeCard };
export type { Props };
