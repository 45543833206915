import * as dateUtils from '../../common/utils/date.utils';
import { Slot, TimeOfDay, SlotGroup } from './types';

export const getAllAvailableSlots = (slots: Slot[]): Slot[] => {
  return slots.filter((slot) => slot.isAvailable);
};

export const generateSlotGroups = (slots: Slot[]): SlotGroup[] => {
  const groups: TimeOfDay[] = ['morning', 'afternoon', 'evening'];

  return groups
    .map((timeOfDay) => ({
      timeOfDay,
      slots: getSlotsFromTimeOfDay(timeOfDay, slots),
    }))
    .filter((group) => group.slots.some((slot) => slot.isAvailable));
};

export const getStartAndEndForTimeOfDay = (
  time: string,
  timeOfDay: TimeOfDay,
): { start: string; end: string } => {
  if (timeOfDay === 'morning') {
    return {
      start: dateUtils.setTime(time, { hour: 6 }),
      end: dateUtils.setTime(time, { hour: 12 }),
    };
  }

  if (timeOfDay === 'afternoon') {
    return {
      start: dateUtils.setTime(time, { hour: 12 }),
      end: dateUtils.setTime(time, { hour: 18 }),
    };
  }

  return {
    start: dateUtils.setTime(time, { hour: 18 }),
    end: dateUtils.setTime(time, { hour: 23 }),
  };
};

export const getSlotsFromTimeOfDay = (timeOfDay: TimeOfDay, slots: Slot[] = []): Slot[] => {
  if (slots.length === 0) {
    return slots;
  }

  const [{ time: firstTime }] = slots;
  const firstDate = dateUtils.stripTimezoneFromDate(firstTime);
  const { start, end } = getStartAndEndForTimeOfDay(firstDate, timeOfDay);

  return slots.filter((slot) => {
    const formattedTime = dateUtils.stripTimezoneFromDate(slot.time);
    const timeIsBeforeGroupEnd = dateUtils.dateIsBefore(formattedTime, end);
    const timeIsAfterGroupStarts = dateUtils.dateIsAfterOrSame(formattedTime, start);

    return timeIsBeforeGroupEnd && timeIsAfterGroupStarts;
  });
};

export const scrollHorizontalIntoView = (elementId: string) => {
  setTimeout(() => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, 200);
};
