import React, { useEffect, useMemo } from 'react';
import { ScrollArea, For } from '@petrus/ui-library';

import { focusHandler } from '../../../common/utils/focus.utils';
import { dateIsSameDate } from '../../../common/utils/date.utils';
import { Availablity } from '../types';
import { scrollHorizontalIntoView } from '../utils';
import { DateCard } from './DateCard';

type Props = {
  loading: boolean;
  availabilityList: Availablity[];
  selectedDate: string;
  defaultSelectFirstAvailable?: boolean;
  onSelect: (date: string) => void;
};

const DatePicker = ({
  availabilityList,
  selectedDate,
  loading,
  defaultSelectFirstAvailable = false,
  onSelect,
}: Props) => {
  const availableDates = useMemo(
    () => availabilityList.filter((d) => d.isAvailable),
    [availabilityList],
  );

  const firstAvailableDateIndex = useMemo(
    () => availabilityList.findIndex((slot) => slot.date === availableDates?.[0]?.date),
    [availabilityList, availableDates],
  );

  useEffect(() => {
    const initialIndex = availabilityList.findIndex((date) => date.isAvailable === true);
    if (initialIndex === -1 && selectedDate) {
      return onSelect(undefined);
    }

    if (initialIndex !== -1 && defaultSelectFirstAvailable) {
      const { date } = availabilityList[initialIndex];
      onSelect(date);
      scrollHorizontalIntoView(`available-date-${initialIndex}`);
    }
  }, [availabilityList, defaultSelectFirstAvailable]);

  return (
    <ScrollArea className="w-full" orientation="vertical">
      <div className="flex space-x-2">
        {loading ? (
          <For
            each={[1, 2, 3, 4]}
            render={(item, index) => (
              <div
                className="p-3 bg-pink/40 rounded-lg animate-pulse"
                style={{ animationDelay: `${index * 0.5}s` }}
              >
                <div className="w-12 h-20" />
              </div>
            )}
          />
        ) : (
          <For
            each={availabilityList}
            render={(availability, index) => {
              const availableIndex = availableDates.findIndex((d) => availability.date === d.date);

              return React.createElement(DateCard, {
                id: `available-date-${index}`,
                key: `available-date-${index}`,
                date: availability.date,
                className: 'py-5',
                onClick: () => onSelect(availability.date),
                onFocus: () => onSelect(availability.date),
                onKeyDown: (e) =>
                  focusHandler(
                    e,
                    `available-date-${availabilityList.findIndex(
                      (d) => d.date === availableDates?.[availableIndex + 1]?.date,
                    )}`,
                    `available-date-${availabilityList.findIndex(
                      (d) => d.date === availableDates?.[availableIndex - 1]?.date,
                    )}`,
                  ),
                selected: dateIsSameDate(availability.date, selectedDate),
                disabled: !availability.isAvailable,
                tabIndex: index === firstAvailableDateIndex ? 0 : -1,
              });
            }}
          />
        )}
      </div>
    </ScrollArea>
  );
};

export { DatePicker };
export type { Props };
