import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { Text, Callout, Heading, For } from '@petrus/ui-library';

import { focusHandler } from '../../../common/utils/focus.utils';
import { KeyboardKeys } from '../../../types';
import { generateSlotGroups, getAllAvailableSlots } from '../utils';
import { Slot } from '../types';
import { TimeCard } from './TimeCard';

type Props = {
  slots: Slot[];
  onSelect: (time: string) => void;
  selectedTime?: string;
};

const TimePicker = ({ slots, onSelect, selectedTime }: Props) => {
  const { t } = useTranslation('common');

  const slotGroups = useMemo(() => generateSlotGroups(slots), [slots]);
  const availableSlots = useMemo(() => getAllAvailableSlots(slots), [slots]);
  const firstAvailableSlotIndex = useMemo(
    () => slots.findIndex((slot) => slot.time === availableSlots?.[0]?.time),
    [slots, availableSlots],
  );

  return (
    <For
      each={slotGroups}
      empty={() => (
        <Callout>
          <div className="text-carmine md:text-center">
            <Heading as="h4" className="md:text-center pb-2">
              {t('domain.availability.no.date')}
            </Heading>
          </div>
        </Callout>
      )}
      render={(group) => (
        <div key={group.timeOfDay} className="mt-1">
          <Text className="text-carmine">
            {t(`domain.availability.timeOfDay.${group.timeOfDay}`)}
          </Text>
          <div className="grid grid-cols-3 gap-2 md:grid-cols-4 mt-2 mb-5">
            <For
              each={group.slots}
              render={(timeSlot) => {
                const index = slots.findIndex((slot) => slot.time === timeSlot.time);
                const availableIndex = availableSlots.findIndex(
                  (slot) => slot.time === timeSlot.time,
                );

                return React.createElement(TimeCard, {
                  id: `slot-${index}`,
                  key: `slot-${index}`,
                  date: timeSlot.time,
                  onClick: () => onSelect(timeSlot.time),
                  onKeyDown: (e: React.KeyboardEvent<HTMLButtonElement>) => {
                    if (e.key === KeyboardKeys.Enter) {
                      onSelect(timeSlot.time);
                      return;
                    }

                    focusHandler(
                      e,
                      `slot-${slots.findIndex(
                        (d) => d.time === availableSlots?.[availableIndex + 1]?.time,
                      )}`,
                      `slot-${slots.findIndex(
                        (d) => d.time === availableSlots?.[availableIndex - 1]?.time,
                      )}`,
                    );
                  },
                  selected: selectedTime === timeSlot.time,
                  disabled: !timeSlot.isAvailable,
                  tabIndex: index === firstAvailableSlotIndex ? 0 : -1,
                });
              }}
            />
          </div>
        </div>
      )}
    />
  );
};

export { TimePicker };
export type { Props };
