import React from 'react';
import Head from 'next/head';
import { isProduction, constants } from 'config';

type Props = {
  path?: string;
  title?: string;
  description?: string;
  lang?: string;
  meta?: Array<{ name: string; content: string }>;
  author?: string;
  keywords?: Array<string>;
  imageUrl?: string;
} & React.HTMLAttributes<HTMLElement>;

const SEO: React.FunctionComponent<Props> = ({
  path = '',
  title = 'Petrus',
  description = '',
  author = '',
  meta = [],
  keywords = [],
  imageUrl,
}) => {
  const metaDescription = description;
  const pageTitle = title;

  const pageImageUrl = imageUrl || constants.defaultSeoImage;
  const siteUrl = isProduction ? 'https://app.petrus.no' : 'https://staging.petrus.no';
  const dynamicSiteUrl = path ? `${siteUrl}${path}` : siteUrl;

  const metaTagsArray = [
    {
      name: 'description',
      content: metaDescription,
    },
    {
      property: 'og:image',
      content: pageImageUrl,
    },
    {
      property: 'og:url',
      content: dynamicSiteUrl,
    },
    {
      property: 'og:title',
      content: pageTitle,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: author,
    },
    {
      name: 'twitter:title',
      content: pageTitle,
    },
    {
      name: 'twitter:description',
      content: metaDescription,
    },
  ];
  if (!isProduction) {
    metaTagsArray.push({
      name: 'robots',
      content: 'noindex',
    });
  }
  const metaTags = metaTagsArray
    .concat(
      keywords && keywords.length > 0
        ? {
            name: 'keywords',
            content: keywords.join(', '),
          }
        : [],
    )
    .concat(meta);

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width" />
      <meta name="next-head-count" content="2" />
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href="/petrus-favicon.png"
        data-react-helmet="true"
      />
      <link rel="canonical" href={dynamicSiteUrl} key="canonical" />
      {metaTags.map((metaTag) => (
        <meta key={`${metaTag.name}-${metaTag.property}`} {...metaTag} />
      ))}
    </Head>
  );
};

export { SEO };
export type { Props };
