import React, { useCallback, useMemo, useState } from 'react';
import { Alert, Callout, Heading, Text } from '@petrus/ui-library';
import { useTranslation } from 'next-i18next';

import posthog from 'posthog-js';
import { dateIsSameDate } from '../../../common/utils/date.utils';
import { Availablity, Slot } from '../types';
import { DatePicker } from './DatePicker';
import { TimePicker } from './TimePicker';

type Props = {
  selectedTime: string;
  loading?: boolean;
  error?: string;
  availabilityList: Availablity[];
  onSelect: (time?: string) => void;
};

const AvailabilityPicker = ({
  availabilityList,
  selectedTime,
  loading,
  error,
  onSelect,
}: Props) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<string>(selectedTime);

  const hasAvailable = useMemo(
    () => availabilityList.find((day) => day.isAvailable),
    [availabilityList],
  );
  const slots: Slot[] = useMemo(() => {
    const day = availabilityList.find((day) => dateIsSameDate(day.date, selectedDate));
    return day?.slots.filter((slot) => slot.isAvailable) ?? [];
  }, [selectedDate, availabilityList]);

  const onDateSelect = useCallback(
    (date: string) => {
      if (date !== selectedDate) {
        onSelect(undefined);
      }
      posthog.capture('Date selected', {
        date,
        slots: slots.map((slot) => ({ time: slot.time, capacity: slot.numberOfAvailableSlots })),
      });
      setSelectedDate(date);
    },
    [onSelect, selectedDate, setSelectedDate],
  );

  return (
    <div className="text-carmine">
      <Alert type="danger" hasError={!!error}>
        {error}
      </Alert>
      <DatePicker
        loading={loading}
        availabilityList={availabilityList}
        selectedDate={selectedDate}
        onSelect={onDateSelect}
        defaultSelectFirstAvailable
      />
      {!!selectedDate && !loading && (
        <div className="mt-4">
          <TimePicker selectedTime={selectedTime} slots={slots} onSelect={onSelect} />
        </div>
      )}
      {!hasAvailable && !loading && (
        <Callout className="mt-2">
          <div className="text-carmine text-center">
            <Heading as="h4" className="pb-4 text-center">
              {t('domain.availability.no.location')}.
            </Heading>
            <Text>{t('domain.availability.no.locationRetry')}</Text>
          </div>
        </Callout>
      )}
    </div>
  );
};

export { AvailabilityPicker };
export type { Props };
